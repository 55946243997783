import { ChevronLeft, ChevronRight } from "lucide-react";

interface SearchPaginationProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
}

export default function SearchPagination({
	currentPage,
	totalPages,
	onPageChange,
}: SearchPaginationProps) {
	const renderPageNumbers = () => {
		const pages = [];
		let start = Math.max(1, currentPage - 2);
		const end = Math.min(totalPages, start + 4);

		if (end - start < 4) {
			start = Math.max(1, end - 4);
		}

		for (let i = start; i <= end; i++) {
			pages.push(
				<button
					key={i}
					onClick={() => onPageChange(i)}
					className={`px-3 py-1 border rounded-md text-sm font-medium ${
						currentPage === i
							? "bg-blue-50 border-blue-500 text-blue-600"
							: "border-gray-300 text-gray-700 hover:bg-gray-50"
					}`}
				>
					{i}
				</button>,
			);
		}
		return pages;
	};

	return (
		<div className="mt-6 flex items-center justify-between bg-white px-4 py-3 sm:px-6">
			<div className="flex flex-1 justify-between sm:hidden">
				<button
					onClick={() => onPageChange(currentPage - 1)}
					disabled={currentPage === 1}
					className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
				>
					前へ
				</button>
				<button
					onClick={() => onPageChange(currentPage + 1)}
					disabled={currentPage === totalPages}
					className="relative ml-3 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
				>
					次へ
				</button>
			</div>

			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						ページ <span className="font-medium">{currentPage}</span> /{" "}
						<span className="font-medium">{totalPages}</span>
					</p>
				</div>
				<div>
					<nav
						className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
						aria-label="Pagination"
					>
						{/* First Page */}
						<button
							onClick={() => onPageChange(1)}
							disabled={currentPage === 1}
							className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<span className="sr-only">First</span>
							<ChevronLeft className="h-4 w-4" />
							<ChevronLeft className="h-4 w-4 -ml-2" />
						</button>

						{/* Previous Page */}
						<button
							onClick={() => onPageChange(currentPage - 1)}
							disabled={currentPage === 1}
							className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<span className="sr-only">Previous</span>
							<ChevronLeft className="h-4 w-4" />
						</button>

						{/* Page Numbers */}
						{renderPageNumbers()}

						{/* Next Page */}
						<button
							onClick={() => onPageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
							className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<span className="sr-only">Next</span>
							<ChevronRight className="h-4 w-4" />
						</button>

						{/* Last Page */}
						<button
							onClick={() => onPageChange(totalPages)}
							disabled={currentPage === totalPages}
							className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<span className="sr-only">Last</span>
							<ChevronRight className="h-4 w-4" />
							<ChevronRight className="h-4 w-4 -ml-2" />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}
