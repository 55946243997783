import { Link } from "@remix-run/react";
import { Users, Music, Clock } from "lucide-react";

export function Navigation() {
	const navItems = [
		{
			title: "歌手から探す",
			description: `気に入りVSingerの歌唱シーンを一覧で`,
			icon: Users,
			href: "/singers",
			color: "bg-purple-100",
			textColor: "text-purple-600",
		},
		{
			title: "曲から探す",
			description: `あの曲のカバー、歌ってみたをまとめて検索`,
			icon: Music,
			href: "/songs",
			color: "bg-pink-100",
			textColor: "text-pink-600",
		},
		{
			title: "配信から探す",
			description: `配信履歴から歌唱シーンを検索`,
			icon: Clock,
			href: "/streams",
			color: "bg-green-100",
			textColor: "text-green-600",
		},
	];

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
			{navItems.map((item) => (
				<Link
					key={item.href}
					to={item.href}
					className="group relative bg-white rounded-xl shadow-sm border border-gray-200 p-6 hover:shadow-md transition-shadow overflow-hidden"
				>
					<div className="flex items-center">
						<div className={`p-2 ${item.color} rounded-lg text-white`}>
							<item.icon className={`h-6 w-6 ${item.textColor}`} />
						</div>
						<div className="ml-4">
							<h3 className="text-lg font-semibold text-gray-900">
								{item.title}
							</h3>
							<p className="text-sm text-gray-500">{item.description}</p>
						</div>
					</div>
					<div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-gray-200 to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform" />
				</Link>
			))}
		</div>
	);
}
