import { Link } from "@remix-run/react";
import { Music, ArrowRight, Trophy } from "lucide-react";

interface PopularSongsProps {
	songs: Array<{
		song_id: string;
		title: string;
		original_artist: string;
		/* trunk-ignore(eslint/@typescript-eslint/no-explicit-any) */
		original_info: Record<string, any>;
		lyrics_url: string;
		sing_count: number;
		latest_stream: string;
		/* trunk-ignore(eslint/@typescript-eslint/no-explicit-any) */
		singers: Record<string, any>;
	}>;
}

export function PopularSongs({ songs }: PopularSongsProps) {
	return (
		<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
			<div className="p-4">
				<div className="flex items-center justify-between mb-2">
					<div>
						<h2 className="text-lg font-medium text-gray-900">人気の楽曲</h2>
						<p className="text-sm text-gray-500 mt-1">直近14日の歌唱回数</p>
					</div>
					<Link
						to="/songs"
						className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
					>
						もっと見る
						<ArrowRight className="ml-1 h-4 w-4" />
					</Link>
				</div>
				<div className="divide-y divide-gray-200">
					{songs.map((song, index) => (
						<Link
							key={song.song_id}
							to={`/songs/${song.song_id}`}
							className="block py-4 hover:bg-gray-50 transition-colors"
						>
							<div className="flex items-center space-x-4">
								<div className="flex-shrink-0">
									{index < 3 ? (
										<div
											className={`w-10 h-10 rounded-full flex items-center justify-center ${
												index === 0
													? "bg-yellow-100 text-yellow-600"
													: index === 1
														? "bg-gray-100 text-gray-600"
														: "bg-orange-100 text-orange-600"
											}`}
										>
											<Trophy className="h-5 w-5" />
										</div>
									) : (
										<div className="w-10 h-10 rounded-full bg-gray-50 flex items-center justify-center">
											<Music className="h-5 w-5 text-gray-400" />
										</div>
									)}
								</div>
								<div className="flex-1 min-w-0">
									<p className="text-sm font-medium text-gray-900 truncate">
										{song.title}
									</p>
									<p className="text-sm text-gray-500 truncate">
										{song.original_artist}
									</p>
								</div>
								<div className="flex items-center space-x-2">
									<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
										{song.sing_count}回
									</span>
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
}
