import { Users, Music, Clock } from "lucide-react";

interface StatisticsProps {
	statistics: {
		totalSingers: number;
		totalSongs: number;
		totalStreams: number;
		lastUpdated: string;
	};
}

export function Statistics({ statistics }: StatisticsProps) {
	const stats = [
		{
			name: "歌い手数",
			value: statistics.totalSingers,
			icon: Users,
			color: "text-purple-600",
			bgColor: "bg-purple-100",
		},
		{
			name: "楽曲数",
			value: statistics.totalSongs,
			icon: Music,
			color: "text-blue-600",
			bgColor: "bg-blue-100",
		},
		{
			name: "歌唱数",
			value: statistics.totalStreams,
			icon: Clock,
			color: "text-green-600",
			bgColor: "bg-green-100",
		},
	];

	return (
		<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
			<div className="p-4">
				<h2 className="sr-only">統計情報</h2>
				<div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
					{stats.map((stat) => (
						<div
							key={stat.name}
							className="relative overflow-hidden rounded-lg bg-white px-4 py-5 border border-gray-100 sm:px-6"
						>
							<dt>
								<div className={`absolute rounded-md ${stat.bgColor} p-3`}>
									<stat.icon
										className={`h-6 w-6 ${stat.color}`}
										aria-hidden="true"
									/>
								</div>
								<p className="ml-16 truncate text-sm font-medium text-gray-500">
									{stat.name}
								</p>
							</dt>
							<dd className="ml-16 flex items-baseline">
								<p className="text-2xl font-semibold text-gray-900">
									{stat.value.toLocaleString()}
								</p>
							</dd>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
