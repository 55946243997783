import {
	useState,
	useRef,
	type ChangeEvent,
	type CompositionEvent,
	type KeyboardEvent,
} from "react";
import { Search, X } from "lucide-react";
import { useSearchParams } from "@remix-run/react";

export function SearchInput() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState(searchParams.get("q") || "");
	const [isComposing, setIsComposing] = useState(false);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const handleSearch = (value: string) => {
		if (!value.trim()) {
			setSearchParams({});
			return;
		}
		setSearchParams({ q: value });
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setInputValue(value);

		if (isComposing) return;

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(() => {
			handleSearch(value);
		}, 500);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && !isComposing) {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
			handleSearch(inputValue);
		}
	};

	const handleCompositionStart = () => {
		setIsComposing(true);
	};

	const handleCompositionEnd = (e: CompositionEvent<HTMLInputElement>) => {
		setIsComposing(false);
		handleSearch(e.currentTarget.value);
	};

	const handleClear = () => {
		setInputValue("");
		setSearchParams({});
	};

	return (
		<div className="w-full max-w-3xl mx-auto">
			<div className="relative">
				<input
					type="text"
					value={inputValue}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onCompositionStart={handleCompositionStart}
					onCompositionEnd={handleCompositionEnd}
					placeholder="曲名 アーティスト名 歌い手名で検索..."
					className="w-full p-4 pl-12 pr-10 bg-white border border-gray-200 rounded-full text-lg text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
					aria-label="検索"
				/>
				<Search
					className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
					size={24}
				/>
				{inputValue && (
					<button
						type="button"
						onClick={handleClear}
						className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-colors"
						aria-label="検索をクリア"
					>
						<X size={20} />
					</button>
				)}
			</div>

			<div className="mt-2 text-sm text-gray-500 px-4 text-center">
				<p className="hidden sm:block">
					複数のキーワードでAND検索ができます（例：「シャルル こばと」）
				</p>
				<p className="sm:hidden">複数キーワードでAND検索可能</p>
			</div>
		</div>
	);
}
