import { useEffect } from "react";
import { useFetcher } from "@remix-run/react";

interface SearchClickLoggerProps {
	children: React.ReactNode;
	searchLogId?: string;
	itemType: "song" | "singer" | "stream";
	itemId: string;
	position?: number;
}

const SearchClickLogger = ({
	children,
	searchLogId,
	itemType,
	itemId,
	position,
}: SearchClickLoggerProps) => {
	const fetcher = useFetcher();

	const handleClick = () => {
		if (!searchLogId) return;

		// クリックログを送信
		fetcher.submit(
			{
				searchLogId,
				itemType,
				itemId,
				position: position?.toString() ?? "",
			},
			{ method: "post" },
		);
	};

	return (
		<div onClick={handleClick} className="cursor-pointer">
			{children}
		</div>
	);
};

export default SearchClickLogger;
