import { Link } from "@remix-run/react";
import { Clock, Calendar } from "lucide-react";
import VideoLink from "../VideoLink";

interface RecentStreamsProps {
	streams: Array<{
		id: string;
		title: string;
		timestamp: string;
		singer: {
			id: string;
			name: string;
		};
		video: {
			id: string;
			title: string;
			video_url: string;
			streamed_at: string;
			thumbnail_url: string | null;
		};
	}>;
}

export function RecentStreams({ streams }: RecentStreamsProps) {
	return (
		<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
			<div className="p-4">
				<div className="flex items-center justify-between mb-4">
					<h2 className="text-lg font-medium text-gray-900">最近の歌枠</h2>
					<Link
						to="/streams"
						className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
					>
						もっと見る
					</Link>
				</div>

				<div className="space-y-4">
					{streams.map((stream) => (
						<div key={stream.id} className="flex gap-4">
							{/* Left side: Thumbnail and date */}
							<div className="w-32 flex-shrink-0">
								{stream.video.thumbnail_url && (
									<div className="relative aspect-video">
										<img
											src={stream.video.thumbnail_url}
											alt=""
											className="w-full h-full object-cover rounded-lg"
											loading="lazy"
										/>
									</div>
								)}
								<div className="mt-2 flex items-center text-xs text-gray-500">
									<Calendar className="w-3 h-3 mr-1" />
									{new Date(stream.video.streamed_at).toLocaleDateString(
										"ja-JP",
									)}
								</div>
							</div>

							{/* Right side: Title and content */}
							<div className="flex-1 min-w-0">
								<Link
									to={`/singers/${stream.singer.id}`}
									className="block text-blue-600 hover:text-blue-800 text-sm font-medium truncate"
								>
									{stream.singer.name}
								</Link>
								<div className="mt-1">
									<VideoLink
										videoId={stream.video.id}
										title={stream.video.title}
										videoUrl={stream.video.video_url}
										timestamp={stream.timestamp}
										className="line-clamp-2 text-sm"
									/>
								</div>
							</div>
						</div>
					))}

					{streams.length === 0 && (
						<div className="text-center py-8 text-gray-500">
							歌唱データがありません
						</div>
					)}
				</div>

				{/* Mobile "See more" button */}
				<div className="mt-6 sm:hidden">
					<Link
						to="/streams"
						className="block w-full text-center px-4 py-2 bg-gray-50 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
					>
						すべての歌枠を見る
					</Link>
				</div>
			</div>
		</div>
	);
}

export default RecentStreams;
