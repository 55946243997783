import { Link } from "@remix-run/react";
import { Music, ArrowRight, Users } from "lucide-react";

interface ActiveSingersProps {
	singers: Array<{
		id: string;
		name: string;
		image_url: string | null;
		stream_count: number;
	}>;
}

export function ActiveSingers({ singers }: ActiveSingersProps) {
	return (
		<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
			<div className="p-4">
				<div className="flex items-center justify-between mb-6">
					<h2 className="text-lg font-medium text-gray-900">
						アクティブな歌い手
					</h2>
					<p className="text-sm mx-2 text-gray-500 mt-1 flex-auto">直近7日間</p>
					<Link
						to="/singers"
						className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
					>
						全ての歌い手を見る
						<ArrowRight className="ml-1 h-4 w-4" />
					</Link>
				</div>
				<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
					{singers.length > 0 &&
						singers.map((singer) => (
							<Link
								key={singer.id}
								to={`/singers/${singer.id}`}
								className="relative group"
							>
								<div className="aspect-square rounded-lg overflow-hidden bg-gray-100">
									{singer.image_url ? (
										<img
											src={singer.image_url}
											alt={singer.name}
											className="w-full h-full object-cover group-hover:opacity-90 transition-opacity"
											loading="lazy"
										/>
									) : (
										<div className="w-full h-full flex items-center justify-center">
											<Users className="h-12 w-12 text-gray-400" />
										</div>
									)}
								</div>
								<div className="mt-2">
									<p className="text-sm font-medium text-gray-900 truncate">
										{singer.name}
									</p>
									<div className="mt-1 flex items-center text-sm text-gray-500 space-x-1">
										<Music className="h-4 w-4" />
										<span>{singer.stream_count}曲</span>
									</div>
								</div>
							</Link>
						))}
				</div>
			</div>
		</div>
	);
}
